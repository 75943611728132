<template>
  <aside class="c-sidebar d-lg-none">
    <ul class="c-sidebar-nav list-unstyled">
      <li class="c-sidebar-nav-item">
        <a
          @click.prevent="onTabClicked('stream')"
          :class="{ 'js-is-active': activeTab === 'stream' }"
          href="#tab-section-video"
          ><svg width="28" height="21"><use xlink:href="#icon-video"></use></svg
        ></a>
      </li>
      <li class="c-sidebar-nav-item">
        <a
          @click.prevent="onTabClicked('wishlist')"
          :class="{ 'js-is-active': activeTab === 'wishlist' }"
          href="#tab-section-wishlist"
        >
          <div class="c-wishlist-icon">
            <svg width="21" height="23">
              <use xlink:href="#icon-wishlist-inactive"></use>
            </svg>
            <span class="c-wishlist-counter">{{ wishlistItems }}</span>
          </div>
        </a>
      </li>
      <li class="c-sidebar-nav-item">
        <a
          @click.prevent="onTabClicked('cart')"
          :class="{ 'js-is-active': activeTab === 'cart' }"
          href="#tab-section-cart"
        >
          <div class="c-cart-icon">
            <svg width="21" height="23"><use xlink:href="#icon-bag"></use></svg>
            <span class="c-cart-counter">{{ cartItems }}</span>
          </div>
        </a>
      </li>
      <li v-show="liveStreamType === 'live'" class="c-sidebar-nav-item">
        <a
          @click.prevent="onTabClicked('chat')"
          :class="{ 'js-is-active': activeTab === 'chat' }"
          href="#tab-section-chat"
          ><svg width="26" height="26"><use xlink:href="#icon-chat"></use></svg
        ></a>
      </li>
      <li class="c-sidebar-nav-item">
        <a
          @click.prevent="onTabClicked('info')"
          :class="{ 'js-is-active': activeTab === 'info' }"
          href="#tab-section-info"
          ><svg width="11" height="25"><use xlink:href="#icon-info"></use></svg
        ></a>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  setup() {},
  methods: {
    onTabClicked(tab) {
      this.$emit("switchMobileTab", tab);
    },
  },
  props: [
    "cartItems",
    "activeTab",
    "playerState",
    "liveStreamType",
    "wishlistItems",
  ],
};
</script>
