<template>
  <section class="c-section c-section--left col-lg-4">
    <div class="c-shop d-flex order-2 order-lg-1 flex-column">
      <div class="c-shop__top" v-if="playerState === 'scheduled' || playerState === 'started'">
        <div class="pt-lg-4 pt-xl-5 ps-0 px-lg-4 px-xxl-5">
          <h3 class="d-none d-lg-block fw-medium">{{ $t("shop.scheduledTitle") }}</h3>
          <div class="c-text c-text--intro d-none d-lg-block">
            <p v-if="playerState === 'scheduled'" class="fw-medium">
              {{ $t("shop.scheduledDescription") }}
            </p>
            <p v-else-if="playerState === 'started'" class="fw-medium">
              {{ $t("shop.showingNow") }}
            </p>
          </div>
        </div>
        <div class="c-shop__top__inner pb-lg-3 ps-0 px-lg-4 px-xxl-5">
          <div
            id="tab-list-now"
            class="c-shop-content"
            :class="{ 'js-is-active': activeTab === 'now' }"
          >
            <div
              v-if="playerState === 'scheduled'"
              :class="{ inactive: playerState === 'scheduled' }"
              class="c-shop-list swiper mt-lg-3"
            >
              <div class="swiper-wrapper">
                <div v-for="index in 6" :key="index" class="swiper-slide">
                  <img
                    class="img-fluid"
                    src="https://live.jbc.hosted-temp.com/images/shop-item.png"
                    width="555"
                    height="99"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div v-else class="c-shop-list swiper mt-lg-3 pt-3 pt-sm-4 pt-lg-0">
              <TransitionGroup
                tag="div"
                class="swiper-wrapper"
                :css="false"
                @before-enter="onBeforeEnter"
                @enter="onEnter"
                @leave="onLeave"
              >
                <ProductCard
                  v-for="(product, index) in activeProducts"
                  :productInfo="product"
                  :variantInfo="
                    product.variation.find(
                      (x) => x.variantCode == product.variantCode
                    )
                  "
                  :key="product.id + product.variantCode"
                  :inWishlist="product.inWishlist"
                  :data-index="index"
                ></ProductCard>
              </TransitionGroup>
              <div class="swiper-scrollbar"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="playerState !== 'scheduled'"
        class="c-shop__bottom flex-fill bg-light"
      >
        <div
          class="c-shop__bottom__inner pt-3 pt-sm-4 pb-3 pb-lg-4 px-lg-4 pb-xl-5 px-xxl-5"
        >
          <div
            id="tab-list-history"
            class="c-shop-content"
            :class="{ 'js-is-active': activeTab === 'passed' }"
          >
            <h3
                class="d-none d-lg-block pt-4"
                v-if="playerState === 'ended'"
            >
              {{ $t("shop.scheduledTitle") }}
            </h3>
            <div class="c-text c-text--intro d-none d-lg-block" v-if="playerState !== 'ended'">
              <p class="fw-medium">{{ $t("shop.justShown") }}</p>
            </div>
            <div class="c-shop-list swiper mt-lg-3">
              <TransitionGroup
                tag="div"
                class="swiper-wrapper"
                :css="false"
                @before-enter="onBeforeEnter"
                @enter="onEnter"
                @leave="onLeave"
              >
                <ProductCard
                  v-for="(product, index) in passedProducts"
                  :productInfo="product"
                  :variantInfo="
                    product.variation.find(
                      (x) => x.variantCode == product.variantCode
                    )
                  "
                  :inWishlist="product.inWishlist"
                  :key="passedProducts.length - index + '_' + product.id"
                  :data-index="index"
                ></ProductCard>
              </TransitionGroup>
              <div class="swiper-scrollbar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- START: Mobile tabs -->
    <!-- Als livestream nog niet actief is, is de 2e tab inactive" -->
    <div class="c-shop-tabs d-lg-none order-1">
      <a
        class="c-tabs-item me-4"
        :class="{
          'js-is-active': activeTab === 'now',
          inactive: playerState === 'ended',
        }"
        href="#tab-list-now"
        data-tab="tab-list-now"
        @click.prevent="onShopTabSwitched('now')"
        >{{ $t("shop.showingNow") }}</a
      >
      <a
        class="c-tabs-item"
        :class="{
          'js-is-active': activeTab === 'passed',
          inactive: !passedProducts.length,
        }"
        href="#tab-list-history"
        data-tab="tab-list-history"
        @click.prevent="onShopTabSwitched('passed')"
        >{{ $t("shop.justShown") }}</a
      >
    </div>
    <!-- END: Mobile tabs -->
  </section>
</template>

<script>
import gsap from "gsap";
import { ref, toRef } from "vue";
import ProductCard from "./ProductCard.vue";
export default {
  setup(props) {
    const activeProducts = toRef(props, "activeProducts");
    const passedProducts = toRef(props, "passedProducts");
    const playerState = toRef(props, "playerState");
    const activeTab = ref("now");

    return { activeProducts, passedProducts, playerState, activeTab };
  },
  watch: {
    playerState: function (newValue, oldValue) {
      if (newValue === "ended") {
        this.activeTab = "passed";
      }
    },
  },
  methods: {
    onShopTabSwitched(tab) {
      this.activeTab = tab;
    },
    onBeforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(-30px)";
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        delay: el.dataset.index * 0.15,
        onComplete: done,
      });
      done();
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        y: 30,
        delay: el.dataset.index * 0.15,
        onComplete: done,
      });
      done();
    },
    onBeforeEnterActive(el) {},
    onEnterActive(el, done) {
      done();
    },
    onLeaveActive(el, done) {
      done();
    },
  },
  props: ["activeProducts", "passedProducts", "playerState"],
  components: {
    ProductCard,
  },
};
</script>

<style>
/* @import "scss/main"; */
</style>
