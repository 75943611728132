<template>
  <div
    class="modal fade close-screen-modal"
    :class="{ show: fade }"
    id="closeScreenmodal"
    tabindex="-1"
    aria-labelledby="Close this screen?"
    aria-modal="true"
    role="dialog"
    v-show="open"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content rounded-1 p-3 p-lg-4 text-center">
        <div class="modal-body px-0 px-ld-4">
          <h2>{{ $t(title) }}</h2>
          <p>
            {{ $t(message) }}
          </p>
        </div>
        <div class="modal-footer border-0 d-flex flex-column px-0 pb-0 px-md-4">
          <button
              type="button"
              class="btn btn-secondary rounded-0 mx-0 text-uppercase w-100"
              v-on:click.prevent="closePopup"
          >
            {{ $t(btnCancel) }}
          </button>
          <button
            type="button"
            class="btn btn-primary rounded-0 mx-0 mt-2 text-uppercase w-100"
            v-on:click.prevent="doRemoveProductFromCart"
          >
            {{ $t(btnConfirm) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from "vue";

export default {
  setup(props) {
    const open = toRef(props, "open");
    const fade = ref(false);
    const title = toRef(props, "title");
    const message = toRef(props, "message");
    const btnCancel = toRef(props, "btnCancel");
    const btnConfirm = toRef(props, "btnConfirm");
    const productData = toRef(props, "productData");

    return {
      open,
      fade,
      title,
      message,
      btnCancel,
      btnConfirm,
      productData,
    };
  },
  props: ["open", "title", "message", "btnCancel", "btnConfirm", "productData"],
  watch: {
    open: function (newValue, oldValue) {
      const self = this;
      if (newValue) {
        setTimeout(function () {
          self.fade = true;
        }, 10);
      } else {
        self.fade = false;
      }
    },
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    doRemoveProductFromCart() {
      this.$emit("doRemoveProductFromCart");
    },
  },
};
</script>