import { createApp } from "vue";
import VueI18n from "vue-i18n";
import Home from "./views/Home";
import YoutubeIframe from "@techassi/vue-youtube-iframe";
import i18n from "./i18n";

window.liveShoppingStream = {};

let defaults = {
    baseUrl: "https://live.cks.jbc.hosted-temp.com",
    wrapper: document.querySelector("#liveShoppingWrapper"),
    video: "f270652a-9e0d-11ec-8d24-17d0b157846b", //Just a default hash
    locale: "nl",
    checkoutLink: "/nl-be/mijn-winkelmandje/",
};
liveShoppingStream.open = function (options) {

    // Code to reuse stream
    // if (typeof liveShoppingStream.wrapper !== 'undefined') {
    //     liveShoppingStream.wrapper.style.display = "block";
    //     // document.getElementById("closeScreenmodal").classList.remove("show");
    //     // document.getElementById("closeScreenmodal").style.display = "none";
    //     setTimeout(function () {
    //         liveShoppingStream.app_wrapper.classList.add("anim");
    //         liveShoppingStream.app_wrapper.classList.remove("to-open");
    //     }, 10);
    //     return;
    // }

    liveShoppingStream.options = { ...defaults, ...options };

    fetch(
        liveShoppingStream.options.baseUrl +
        "/livestream/" +
        liveShoppingStream.options.locale +
        "/" +
        liveShoppingStream.options.video
    )
        .then((response) => response.json())
        .then((data) => {
            liveShoppingStream.configData = data;

            //We just need to create the proper containers (for the modal setup)
            liveShoppingStream.wrapper = liveShoppingStream.options.wrapper;
            liveShoppingStream.wrapper.style.display = "none";

            //Modal background
            liveShoppingStream.modal_bg = document.createElement("div");
            liveShoppingStream.modal_bg.classList.add("jbcls__modal_bg");

            //Modal
            liveShoppingStream.modal = document.createElement("div");
            liveShoppingStream.modal.classList.add("jbcls__modal");

            //App wrapper
            liveShoppingStream.app_wrapper = document.createElement("div");
            liveShoppingStream.app_wrapper.classList.add("app_wrapper");

            //Appending background to wrapper
            liveShoppingStream.modal.append(liveShoppingStream.app_wrapper);
            liveShoppingStream.modal_bg.append(liveShoppingStream.modal);
            liveShoppingStream.wrapper.append(liveShoppingStream.modal_bg);
            liveShoppingStream.app_wrapper.classList.add("to-open", "liveShopping");

            liveShoppingStream.vueApp = createApp(Home)
                .use(YoutubeIframe)
                .use(i18n)
                .mount(liveShoppingStream.app_wrapper);

            liveShoppingStream.app_wrapper
                .querySelector(".js-close-confirm")
                .addEventListener("click", liveShoppingStream.close);

            liveShoppingStream.wrapper.style.display = "block";
            setTimeout(function () {
                liveShoppingStream.app_wrapper.classList.add("anim");
                liveShoppingStream.app_wrapper.classList.remove("to-open");

                liveShoppingStream.vueApp.popupOpened();
            }, 10);

            //And finally already mount the application in a "default" state, the open function will trigger the modal animation & fetch all necessary stream data + link & locale
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};

liveShoppingStream.close = function () {
    liveShoppingStream.app_wrapper.classList.add("to-open");
    liveShoppingStream.vueApp.popupClosed();

    setTimeout(function () {
        liveShoppingStream.wrapper.style.display = "none";
        liveShoppingStream.modal_bg.remove();
    }, 400);
};

//Code for checking callback function to trigger opening the popup
let url = new URL(document.currentScript.src);
if (url.searchParams.get("callback")) {
    function triggerCallback(path, currentObj) {
        currentObj = typeof currentObj !== "undefined" ? currentObj : window;

        if (path.length > 1) {
            //We'll need to travers deeper
            let tmpObj = path.shift();

            if (typeof currentObj[tmpObj] == "object") {
                triggerCallback(path, currentObj[tmpObj]);
            } else {
                console.error(
                    "The target callback doesn't appear to be a object, yet the callback string is pointing us deeper"
                );
            }
        } else if (path.length > 0) {
            if (typeof currentObj[path[0]] == "function") {
                // console.log("Calling function: ", currentObj[path[0]]);
                currentObj[path[0]](liveShoppingStream);
            } else {
                console.error("The target callback doesn't appear to be a function?");
            }
        } else {
            console.error("We've apperently happend upon a death end?!");
        }
    }

    let callBack = url.searchParams.get("callback");
    let callBackObjects = [callBack];

    if (callBack.indexOf(".") > -1) {
        callBackObjects = callBack.split(".");
    }

    triggerCallback(callBackObjects);
}